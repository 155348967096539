import { Capacitor } from '@capacitor/core';
import { useUserStore } from "@/stores/user";
import { useOnboardingStore } from "@/stores/onboarding";
import { useNavStore } from "@/stores/nav";
import { useRouter } from "vue-router";
import { CapacitorHttp } from '@capacitor/core';
import useOnboarding from "@/apis/useOnboardingApi.js";

export default () => {

    const userStore = useUserStore();
    const navStore = useNavStore();
    const { enableFinanceOrAnalytics } = useOnboarding();
    const router = useRouter();
    const onboardingStore = useOnboardingStore();
    const isNativeApp = Capacitor.isNativePlatform();

    const createUser = async (apiUrl, token, firstName, lastName, email, password, phone, lead) => {
        const options = {
            url: `${apiUrl}/api/v1/users` + navStore.utmTagsToString(),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                user: {
                    email: email.toLowerCase(),
                    password: password,
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phone,
                    accepted_tcs: 1
                },
                lead: lead
            }
        };

        try {
            const response = await CapacitorHttp.post(options);
            if (response.status === 200) {
                if (!isNativeApp) userStore.$cookies.set('logged_user', 'true');
                userStore.userObj = {
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone_number,
                    token: response.data.token,
                    firebase_tokens: response.data.firebase_tokens,
                    companies: response.data.companies,
                    initCompanyName: response.data.company_name,
                    activeCompany: response.data.companies ? response.data.companies[0] : {},
                    plaidReadyFlag: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.plaid_transactions_ready : false,
                    subscription: response.data.companies && response.data.companies[0].subscription ? response.data.companies[0].subscription : null,
                    gridScoreReadyFlag: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.grid_score_ready : false,
                    //analyticsBeta: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.beta_tester : false,
                    analyticsBeta: true,
                    merchantReadyFlag: response.data.companies && response.data.companies[0].products_config && response.data.companies[0].products_config.payable_cash_advance_loans_present ? response.data.companies[0].products_config.payable_cash_advance_loans_present : false,
                    profileCompletedFlag: response.data.profile_completed,
                    onBoardingComplete: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.onboarding_complete : false,
                    loanApplicationId: response.data.companies && response.data.companies[0].active_project_draft ? response.data.companies[0].active_project_draft.id : null,
                    accounts: response.data.plaid_accounts,
                    activeAccount: response.data.plaid_accounts ? response.data.plaid_accounts[0] : {},
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path,
                    loggedInUser: true,
                    isAdmin: response.data.group && response.data.group.id >= 500,
                    isBorrower: response.data.group && response.data.group.user_kind == 'borrower',
                    isSignatory: response.data.group && response.data.group.user_kind == 'signatory',
                    isMasqueraded: response.data.user_masqueraded && response.data.user_masqueraded,
                    subscription: response.data.companies && response.data.companies[0].subscription ? response.data.companies[0].subscription : null,
                    hasCcr: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.credit_history_enabled : false,
                    displayCcrBanner: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.credit_history_ready_banner_display : false,
                    hasOpenBanking: response.data.companies ? response.data.companies[0].activated_open_banking : false,
                    hasCompanyHealth: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.company_health_enabled : false,
                    displayWelcomeScreen: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.display_welcome_screen : false,
                    hasAnalytics: response.data.companies && response.data.companies[0].products_config && response.data.plaid_items && response.data.plaid_items.length > 0 ? response.data.companies[0].products_config.analytics_enabled : false,
                    hasFinance: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.finance_enabled : false,
                    hasLegal: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.legal_enabled : false,
                    hasCashflow: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.cashflow_enabled : false,
                    hasRequestedCcr: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.credit_history_requested : false,
                    hasAllIdentitiesApproved: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.all_identities_approved : false,
                    cashflow2WeeksEnabled: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.cashflow_created_at : null,
                    showTooltip: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.show_cashflow_tooltip : false,
                    journeyOnboarding: response.data.onboarding_journey && response.data.onboarding_journey,
                    firstJourneyCompleted: response.data.first_journey_completed && response.data.first_journey_completed
                };

                return {
                    success: true,
                    email: response.data.email,
                    server: apiUrl
                };
            } else {
                console.log('response api: ', response);
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const loginUser = async (apiUrl, token, email, password) => {
        const options = {
            url: apiUrl + '/api/v1/auth/login/',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            webFetchExtra: {
                credentials: "include",
            },
            data: {
                user: {
                    email: email.toLowerCase(),
                    password: password
                }
            }
        };
        try {
            const response = await CapacitorHttp.post(options);
            if (response.status === 200) {
                if (response.data.companies) await updateActiveCompany(response.data.companies);
                const activeCompany = (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany : (response.data.companies && response.data.companies[0]);
                if (!isNativeApp) userStore.$cookies.set('logged_user', 'true');
                userStore.userObj = {
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone_number,
                    token: response.data.token,
                    firebase_tokens: response.data.firebase_tokens,
                    companies: response.data.companies && response.data.companies,
                    initCompanyName: response.data.company_name,
                    activeCompany: activeCompany,
                    plaidReadyFlag: activeCompany && activeCompany.products_config ? activeCompany.products_config.plaid_transactions_ready : false,
                    subscription: activeCompany && activeCompany.subscription ? activeCompany.subscription : null,
                    gridScoreReadyFlag: activeCompany && activeCompany.products_config ? activeCompany.products_config.grid_score_ready : false,
                    //analyticsBeta: activeCompany && activeCompany.products_config ? activeCompany.products_config.beta_tester : false,
                    analyticsBeta: true,
                    merchantReadyFlag: activeCompany && activeCompany.products_config && activeCompany.products_config.payable_cash_advance_loans_present ? activeCompany.products_config.payable_cash_advance_loans_present : false,
                    profileCompletedFlag: response.data.profile_completed,
                    onBoardingComplete: activeCompany && activeCompany.products_config ? activeCompany.products_config.onboarding_complete : false,
                    loanApplicationId: activeCompany && activeCompany.active_project_draft ? activeCompany.active_project_draft.id : null,
                    accounts: response.data.plaid_accounts,
                    activeAccount: (userStore.userObj.activeAccount && Object.keys(userStore.userObj.activeAccount).length > 0) ? userStore.userObj.activeAccount : (response.data.plaid_accounts && response.data.plaid_accounts[0]),
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path,
                    loggedInUser: true,
                    isAdmin: response.data.group && response.data.group.id >= 500,
                    isBorrower: response.data.group && response.data.group.user_kind == 'borrower',
                    isSignatory: response.data.group && response.data.group.user_kind == 'signatory',
                    isMasqueraded: response.data.user_masqueraded && response.data.user_masqueraded,
                    subscription: response.data.companies && response.data.companies[0].subscription ? response.data.companies[0].subscription : null,
                    hasCcr: activeCompany && activeCompany.products_config ? activeCompany.products_config.credit_history_enabled : false,
                    displayCcrBanner: activeCompany && activeCompany.products_config ? activeCompany.products_config.credit_history_ready_banner_display : false,
                    hasCompanyHealth: activeCompany && activeCompany.products_config ? activeCompany.products_config.company_health_enabled : false,
                    displayWelcomeScreen: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.display_welcome_screen : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.display_welcome_screen : false,
                    hasAnalytics: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && response.data.plaid_items && response.data.plaid_items.length > 0 && userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config.analytics_enabled : activeCompany && activeCompany.products_config && response.data.plaid_items && response.data.plaid_items.length > 0 ? response.data.companies && response.data.companies[0].products_config.analytics_enabled : false,
                    hasFinance: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.finance_enabled : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.finance_enabled : false,
                    hasCashflow: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.cashflow_enabled : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.cashflow_enabled : false,
                    hasLegal: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.legal_enabled : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.legal_enabled : false,
                    hasOpenBanking: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.activated_open_banking : activeCompany ? response.data.companies && response.data.companies[0].activated_open_banking : false,
                    hasRequestedCcr: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.credit_history_requested : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.credit_history_requested : false,
                    hasAllIdentitiesApproved: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.all_identities_approved : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.all_identities_approved : false,
                    cashflow2WeeksEnabled: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.cashflow_created_at : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.cashflow_created_at : null,
                    showTooltip: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.show_cashflow_tooltip : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.show_cashflow_tooltip : false,
                    journeyOnboarding: response.data.onboarding_journey && response.data.onboarding_journey,
                    firstJourneyCompleted: response.data.first_journey_completed && response.data.first_journey_completed
                };

                if (activeCompany && activeCompany.onboarding) {
                    let allOnboarding = {
                        ...response.data.onboarding,
                        ...activeCompany.onboarding
                    }
                    await onboardingStore.updateActiveOnboardingStates(allOnboarding)
                } else {
                    await onboardingStore.updateActiveOnboardingStates(response.data.onboarding)
                }

                return {
                    success: true,
                    server: apiUrl,
                    fb_token_update: !response.data.firebase_token || (response.data.firebase_token && response.data.firebase_token !== fbToken),
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path
                };
            } else {
                return {
                    success: false,
                    error: response.data.error,
                    msg: 'Could not complete user sign in. Please check username and/or password.'
                }
            }
        } catch (err) {
            return err;
        }
    }

    const getCurrentUser = async (apiUrl, token, userToken, userId) => {
        const options = {
            url: apiUrl + `/api/v1/users/${userId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            webFetchExtra: {
                credentials: "include",
            },
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                if (response.data.companies) await updateActiveCompany(response.data.companies);
                const activeCompany = (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany : (response.data.companies && response.data.companies[0]);
                if (!isNativeApp) userStore.$cookies.set('logged_user', 'true');
                userStore.userObj = {
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone_number,
                    token: response.data.token,
                    firebase_tokens: response.data.firebase_tokens,
                    companies: response.data.companies && response.data.companies,
                    initCompanyName: response.data.company_name,
                    activeCompany: activeCompany,
                    plaidReadyFlag: activeCompany && activeCompany.products_config ? activeCompany.products_config.plaid_transactions_ready : false,
                    subscription: activeCompany && activeCompany.subscription ? activeCompany.subscription : null,
                    gridScoreReadyFlag: activeCompany && activeCompany.products_config ? activeCompany.products_config.grid_score_ready : false,
                    //analyticsBeta: activeCompany && activeCompany.products_config ? activeCompany.products_config.beta_tester : false,
                    analyticsBeta: true,
                    merchantReadyFlag: activeCompany && activeCompany.products_config && activeCompany.products_config.payable_cash_advance_loans_present ? activeCompany.products_config.payable_cash_advance_loans_present : false,
                    profileCompletedFlag: response.data.profile_completed,
                    onBoardingComplete: activeCompany && activeCompany.products_config ? activeCompany.products_config.onboarding_complete : false,
                    loanApplicationId: activeCompany && activeCompany.active_project_draft ? activeCompany.active_project_draft.id : null,
                    accounts: response.data.plaid_accounts,
                    activeAccount: (userStore.userObj.activeAccount && Object.keys(userStore.userObj.activeAccount).length > 0) ? userStore.userObj.activeAccount : (response.data.plaid_accounts && response.data.plaid_accounts[0]),
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path,
                    loggedInUser: true,
                    isAdmin: response.data.group && response.data.group.id >= 500,
                    isBorrower: response.data.group && response.data.group.user_kind == 'borrower',
                    isSignatory: response.data.group && response.data.group.user_kind == 'signatory',
                    isMasqueraded: response.data.user_masqueraded && response.data.user_masqueraded,
                    subscription: response.data.companies && response.data.companies[0].subscription ? response.data.companies[0].subscription : null,
                    hasCcr: activeCompany && activeCompany.products_config ? activeCompany.products_config.credit_history_enabled : false,
                    displayCcrBanner: activeCompany && activeCompany.products_config ? activeCompany.products_config.credit_history_ready_banner_display : false,
                    hasOpenBanking: activeCompany ? activeCompany.activated_open_banking : false,
                    hasCompanyHealth: activeCompany && activeCompany.products_config ? activeCompany.products_config.company_health_enabled : false,
                    displayWelcomeScreen: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.display_welcome_screen : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.display_welcome_screen : false,
                    hasAnalytics: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && response.data.plaid_items && response.data.plaid_items.length > 0 && userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config.analytics_enabled : activeCompany && activeCompany.products_config && response.data.plaid_items && response.data.plaid_items.length > 0 ? response.data.companies && response.data.companies[0].products_config.analytics_enabled : false,
                    hasFinance: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.finance_enabled : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.finance_enabled : false,
                    hasCashflow: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.cashflow_enabled : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.cashflow_enabled : false,
                    hasLegal: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.legal_enabled : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.legal_enabled : false,
                    hasRequestedCcr: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.credit_history_requested : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.credit_history_requested : false,
                    hasAllIdentitiesApproved: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.all_identities_approved : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.all_identities_approved : false,
                    cashflow2WeeksEnabled: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.cashflow_created_at : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.cashflow_created_at : null,
                    showTooltip: (userStore.userObj.activeCompany && Object.keys(userStore.userObj.activeCompany).length > 0) ? userStore.userObj.activeCompany && userStore.userObj.activeCompany.products_config && userStore.userObj.activeCompany.products_config.show_cashflow_tooltip : activeCompany && activeCompany.products_config ? response.data.companies && response.data.companies[0].products_config.show_cashflow_tooltip : false,
                    journeyOnboarding: response.data.onboarding_journey && response.data.onboarding_journey,
                    firstJourneyCompleted: response.data.first_journey_completed && response.data.first_journey_completed
                };

                await userStore.setUserMobilePreferences('GridUserMobile', userStore.userObj);

                if (activeCompany && activeCompany.onboarding) {
                    let allOnboarding = {
                        ...response.data.onboarding,
                        ...activeCompany.onboarding
                    }
                    await onboardingStore.updateActiveOnboardingStates(allOnboarding)
                } else {
                    await onboardingStore.updateActiveOnboardingStates(response.data.onboarding)
                }

                return {
                    success: true,
                    server: apiUrl,
                    fb_token_update: !response.data.firebase_token || (response.data.firebase_token && response.data.firebase_token !== fbToken),
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path
                };
            } else {
                return {
                    success: false,
                    error: response.data.error,
                    msg: 'Could not complete user sign in. Please check username and/or password.'
                }
            }
        } catch (err) {
            return err;
        }
    }

    const logoutUser = async (apiUrl, token, userToken) => {
        const options = {
            url: apiUrl + '/api/v1/auth/logout/',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            webFetchExtra: {
                credentials: "include",
            },
        };
        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                navStore.removeUrlPath();
                userStore.userCookie = '';
                userStore.$cookies.remove('logged_user');
                userStore.userObj = {
                    id: '',
                    name: '',
                    email: '',
                    phone: null,
                    token: '',
                    fbToken: '',
                    companies: [],
                    initCompanyName: '',
                    activeCompany: {},
                    plaidReadyFlag: false,
                    subscription: null,
                    analyticsBeta: true,
                    gridScoreReadyFlag: false,
                    activeAccount: {},
                    loggedInUser: false,
                    isAdmin: false,
                    isBorrower: false,
                    isSignatory: false,
                    isMasqueraded: false,
                    subscription: null,
                    hasCcr: false,
                    hasOpenBanking: false,
                    displayWelcomeScreen: false,
                    hasCompanyHealth: false,
                    hasAnalytics: false,
                    hasFinance: false,
                    hasCashflow: false,
                    hasLegal: false,
                    hasRequestedCcr: false,
                    hasAllIdentitiesApproved: false,
                    cashflow2WeeksEnabled: null,
                    showTooltip: false,
                    journeyOnboarding: false,
                    firstJourneyCompleted: null
                };
                navStore.removePreviousPath();
                return {
                    success: true
                };
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    }

    const forgotPassword = async (apiUrl, token, email) => {
        const options = {
            url: apiUrl + `/api/v1/users/forgot_password?email=${email}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            return [response.data, response.status];
        } catch (err) {
            return [err, response.status];
        }
    }

    const updateActiveCompany = async (companies) => {
        if (companies && userStore.userObj && userStore.userObj.activeCompany) {
            let activeCompanyLookup = companies.find(company => company.id === userStore.userObj.activeCompany.id);
            if (activeCompanyLookup) userStore.userObj.activeCompany = activeCompanyLookup;
        }
    }

    return {
        createUser,
        getCurrentUser,
        loginUser,
        logoutUser,
        forgotPassword
    }
}
