import { Capacitor } from '@capacitor/core';
import { useUserStore } from "@/stores/user";
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { isPlatform, menuController } from "@ionic/vue";
import { useNavStore } from "@/stores/nav";;

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const appPages = [
  { url: '/' },
  { url: '/analytics' },
  { url: '/finance' },
  { url: '/finance/application' },
  { url: '/finance/rai' },
  { url: '/finance/guaranteed_irish' },
  { url: '/finance/chambers_fingal' },
  { url: '/finance/chambers_limerick' },
  { url: '/finance/chambers_letterkenny' },
  { url: '/finance/chambers_dublin' },
  { url: '/finance/chambers_cork' },
  { url: '/finance/dashboard' },
  { url: '/finance/submitted_application' },
  { url: '/finance/merchant_changeover' },
  { url: '/finance/loan_offer' },
  { url: '/partner/connect_account' },
  { url: '/top_up' },
  // { url: '/an_post' },
  { url: '/partners' },
  { url: '/contact_us' },
  { url: '/about_us' },
  { url: '/about/team' },
  { url: '/about/foundation' },
  { url: '/dashboard' },
  { url: '/loans' },
  { url: '/repayments' },
  { url: '/reporting' },
  { url: '/help_centre' },
  { url: '/profile' },
  { url: '/company' },
  { url: '/login' },
  { url: '/dashboard_alt' },
  { url: '/articles' },
  { url: '/testimonials' },
  { url: '/showcase' },
  { url: '/showcase' },
  { url: '/downloads' },
  { url: '/account/billing' },
  { url: '/account/billing/ios' },
  { url: '/help_centre/resources' },
  { url: '/help_centre/faqs' },
  { url: '/help_centre/terms_conditions' },
  { url: '/help_centre/guide' },
  { url: '/finance/turnover' },
  { url: '/finance/merchant_transactions' },
  { url: '/home' },
  { url: '/analytics/calendar' },
  { url: '/analytics/banking_analysis' },
  { url: '/analytics/transaction_analysis' },
  { url: '/analytics/categorised_transaction_analysis' },
  { url: '/analytics/sector_comparison' },
  { url: '/analytics/macroeconomic_hub' },
  { url: '/analytics/grid_score' },
  { url: '/analytics/company_health' },
  /*{ url: '/analytics/credit_bureau_report' },*/
  { url: '/account/analytics_billing' },
  { url: '/account/manage_open_banking' },
  { url: '/account/company_details' },
  { url: '/account/user_profile' },
  { url: '/account/identity_documents' },
  { url: '/open_banking_instructions' },
  { url: '/sme_health_index_survey' },
  { url: '/notifications' },
];

const sheets = [
  /*{
    title: "Home",
    name: "Home",
    url: "/",
    iosIcon: 'HomeIcon',
    meta: { showSideMenu: false },
    mdIcon: 'HomeIcon',
  },*/
  {
    title: "Analytics",
    name: "Analytics",
    meta: { showSideMenu: true },
    iosIcon: 'ChartPieIcon',
    mdIcon: 'ChartPieIcon',
    hide: false, //!analyticsBeta,
    nestedPages: [
      {
        title: "Home",
        name: "Home",
        url: "/home",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Calendar",
        name: "Calendar",
        url: "/home/calendar",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      /*{
        title: "GRID Score",
        name: "GRID Score",
        url: "/analytics/grid_score",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },*/
      /*{
        title: "Credit Bureau Report",
        name: "Credit Bureau Report",
        url: "/analytics/credit_bureau_report",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
        hide: false//!userStore.userObj.hasCcr
      },*/
      {
        title: "Banking Analysis",
        name: "Your Bank Account",
        url: "/analytics/banking_analysis",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
        hide: false//(userStore.userObj && !userStore.userObj.hasCashflow),
      },
      /*{
        title: "Company Health",
        name: "Company Health",
        url: "/analytics/company_health",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
        hide: false//!userStore.userObj.hasCompanyHealth
      },*/
      {
        title: "Transaction Analysis",
        name: "Account Transactions",
        url: "/analytics/transaction_analysis",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
        hide: false//(userStore.userObj && !userStore.userObj.hasCashflow),
      },
      {
        title: "Categorised Transaction Analysis",
        name: "Account Trends",
        url: "/analytics/categorised_transaction_analysis",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
        hide: false//(userStore.userObj && !userStore.userObj.hasCashflow),
      },
      {
        title: "Debt History",
        name: "Debt History",
        url: "/home/debt_history",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
        hide: false//(userStore.userObj && !userStore.userObj.hasCashflow),
      },
      /*{
        title: "Sector Comparison",
        name: "Similar Businesses",
        url: "/analytics/sector_comparison",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Macroeconomic Hub",
        name: "Economic Trends",
        url: "/analytics/macroeconomic_hub",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },*/
    ]
  },
  /*{
    title: "Pricing",
    name: "Pricing",
    url: nativeIosMobileDevice ? "/account/billing/ios" : "/account/billing",
    iosIcon: CashIcon,
    meta: { showSideMenu: true },
    mdIcon: CashIcon,
  },*/
  {
    title: "Finance",
    name: "Finance",
    meta: { showSideMenu: true },
    iosIcon: 'CreditCardIcon',
    mdIcon: 'CreditCardIcon',
    hide: false,//(userStore.userObj && !userStore.userObj.hasFinance),
    nestedPages: [
      {
        title: "Finance Dashboard",
        name: "Finance Dashboard",
        url: "/finance/dashboard",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Loan Applications",
        name: "Loan Applications",
        url: "/finance/application",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Your Loans",
        name: "Your Loans",
        url: "/finance/loans",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Loan Repayments",
        name: "Loan Repayments",
        url: "/finance/repayments",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Term Loan Repayments",
        name: "Term Loan Repayments",
        url: "/finance/term_repayments",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Merchant Transactions",
        name: "Merchant Transactions",
        url: "/finance/merchant_transactions",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      /*{
        title: "Merchant Turnover",
        name: "Your Card Income",
        url: "/finance/turnover",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },*/
      {
        title: "Terminal Income",
        name: "Terminal Income",
        url: "/finance/terminal_income",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Merchant Changeover",
        name: "Merchant Changeover",
        url: "/finance/merchant_changeover",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
    ]
  },
  {
    title: "Notifications",
    name: "Notifications",
    url: "/notifications",
    iosIcon: 'BellIcon',
    meta: { showSideMenu: true },
    mdIcon: 'BellIcon',
    hide: false//hideBool,
  },
  {
    title: "Downloads",
    name: "Downloads",
    url: "/downloads",
    iosIcon: 'DownloadIcon',
    meta: { showSideMenu: true },
    mdIcon: 'DownloadIcon',
    hide: false//hideBool,
  },
  {
    title: "Help Centre",
    name: "Help Centre",
    meta: { showSideMenu: false },
    iosIcon: 'InformationCircleIcon',
    mdIcon: 'InformationCircleIcon',
    hide: false,//hideBool,
    nestedPages: [
      /*{
        title: "Chat to someone",
        name: "Chat to someone",
        url: "/analytics/dashboard",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Contact us",
        name: "Contact us",
        url: "/analytics/demo_dashboard_2",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "FAQ's",
        name: "FAQ's",
        url: "/analytics/dashboard",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },*/
      {
        title: "Your Dashboard",
        name: "Your Dashboard",
        url: "/help_centre/guide",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Support",
        name: "Support",
        url: "/help_centre/support",
        hide: false,
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Privacy Policy",
        name: "Privacy Policy",
        url: "/help_centre/privacy_policy",
        hide: false,
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Terms & Conditions",
        name: "Terms & Conditions",
        url: "/help_centre/terms_conditions",
        hide: false,
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Resources",
        name: "Resources",
        url: "https://app.gridfinance.ie/shared/support/resources",
        externalUrl: true,
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "FAQ's",
        name: "FAQ's",
        url: "/help_centre/faqs",
        iosIcon: '',
        meta: { showSideMenu: false },
        mdIcon: '',
      },
    ]
  },
  {
    title: "Settings",
    name: "Settings",
    meta: { showSideMenu: true },
    iosIcon: 'CogIcon',
    mdIcon: 'CogIcon',
    hide: false,//hideBool,
    nestedPages: [
      {
        title: "My Company",
        name: "My Company",
        url: "/account/company_details",
        iosIcon: '',
        hide: true,
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "User Profile",
        name: "User Profile",
        url: "/account/user_profile",
        iosIcon: '',
        hide: true,
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "Identity Documents",
        name: "Identity Documents",
        url: "/account/identity_documents",
        iosIcon: '',
        meta: { showSideMenu: false },
        mdIcon: '',
      },
      /*{
        title: "Signatory Identification",
        name: "Signatory Identification",
        url: "/finance/application/signatories",
        iosIcon: '',
        meta: { showSideMenu: false },
        mdIcon: '',
      },*/
      {
        title: "Billing",
        name: "Billing",
        hide: true,
        url: "/account/billing",//nativeIosMobileDevice ? "/account/billing/ios" : "/account/billing",
        iosIcon: '',
        meta: { showSideMenu: false },
        mdIcon: '',
      },
      {
        title: "Open Banking",
        name: "Open Banking",
        url: "/account/manage_open_banking",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
    ]
  },
  /*{
    title: "Profile",
    name: "Notifications",
    url: "/home/profile",
    iosIcon: 'UserCircleIcon',
    meta: { showSideMenu: true },
    mdIcon: 'UserCircleIcon',
    hide: hideBool,
  },*/
  /*{
    title: "Settings",
    name: "Settings",
    meta: { showSideMenu: true },
    iosIcon: CogIcon,
    mdIcon: CogIcon,
    nestedPages: [
      {
        title: "Company Profile",
        name: "Company Profile",
        url: "/analytics/dashboard",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
      {
        title: "User Profile",
        name: "User Profile",
        url: "/analytics/demo_dashboard_2",
        iosIcon: '',
        meta: { showSideMenu: true },
        mdIcon: '',
      },
    ]
  },*/
]

const publicPages = [
  {
    title: "Homepage",
    name: "Homepage",
    url: "/",
    iosIcon: 'HomeIcon',
    meta: { showSideMenu: false },
    mdIcon: 'HomeIcon',
    hide: true
  },
  {
    title: "Business Finance",
    name: "Business Finance",
    url: "/finance",
    iosIcon: 'CreditCardIcon',
    meta: { showSideMenu: false },
    mdIcon: 'CreditCardIcon',
    hide: false
  },
  {
    title: "Short Term Business Loans",
    name: "Short Term Business Loans",
    url: "/short_term_loans",
    iosIcon: 'CreditCardIcon',
    meta: { showSideMenu: false },
    mdIcon: 'CreditCardIcon',
    hide: false
  },
  {
    title: "Analytics",
    name: "Analytics",
    url: "/analytics",
    meta: { showSideMenu: false },
    iosIcon: 'ChartPieIcon',
    mdIcon: 'ChartPieIcon',
    hide: false
  },
  {
    title: "Partners",
    name: "Partners",
    url: "/partners",
    meta: { showSideMenu: false },
    iosIcon: 'UsersIcon',
    mdIcon: 'UsersIcon',
    hide: false
  },
  {
    title: "Contact Us",
    name: "Contact Us",
    url: "/contact_us",
    meta: { showSideMenu: false },
    iosIcon: 'PhoneIcon',
    mdIcon: 'PhoneIcon',
    hide: false
  },
  {
    title: "Team",
    name: "Team",
    url: "/about/team",
    meta: { showSideMenu: false },
    iosIcon: 'UserGroupIcon',
    mdIcon: ' UserGroupIcon',
    hide: false
  },
  {
    title: "About Us",
    name: "About Us",
    url: "/about_us",
    meta: { showSideMenu: false },
    iosIcon: 'UserGroupIcon',
    mdIcon: ' UserGroupIcon',
    hide: false
  },
  {
    title: "Foundation",
    name: "Foundation",
    url: "/about/foundation",
    meta: { showSideMenu: false },
    iosIcon: 'GlobeIcon',
    mdIcon: 'GlobeIcon',
    hide: false
  },
];

const routes = [
  {
    path: '',
    component: Capacitor.isNativePlatform() ? () => import('@/pages/LoginPage') : () => import('@/pages/HomePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/contact_us',
    component: () => import(/* webpackChunkName: "ContactUsPage" */ '@/pages/ContactUsPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/analytics',
    component: () => import(/* webpackChunkName: "AnalyticsPage" */ '@/pages/AnalyticsPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/insights/grid_score',
    component: () => import(/* webpackChunkName: "AnalyticsGridScorePage" */ '@/pages/AnalyticsGridScorePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/analytics/apps',
    component: () => import(/* webpackChunkName: "AnalyticsAppsPage" */ '@/pages/AnalyticsAppsPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/dashboard',
    component: () => import(/* webpackChunkName: "BankingAnalysis" */ '@/sheets/analytics/BankingAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "AnalyticsHome" */ '@/sheets/analytics/AnalyticsHome'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/welcome',
    component: () => import(/* webpackChunkName: "AnalyticsLanding" */ '@/sheets/analytics/AnalyticsLanding'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/plan',
    component: () => import(/* webpackChunkName: "AnalyticsLanding" */ '@/sheets/analytics/shared/AnalytyicsPlan'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/plan/upgrade',
    component: () => import(/* webpackChunkName: "AnalyticsLanding" */ '@/sheets/analytics/shared/AnalytyicsPlanUpgrade'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/plan/trial',
    component: () => import(/* webpackChunkName: "AnalyticsLanding" */ '@/sheets/analytics/shared/AnalytyicsPlanTrial'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/open_banking',
    component: () => import(/* webpackChunkName: "AnalyticsLanding" */ '@/sheets/analytics/shared/AnalytyicsOpenBanking'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/mobile/landing',
    component: () => import(/* webpackChunkName: "AnalyticsLanding" */ '@/onboarding/shared/mobileOnboardingPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/calendar',
    component: () => import(/* webpackChunkName: "AnalyticsCalendar" */ '@/sheets/analytics/calendar/AnalyticsCalendar'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/calendar/annual_return_details',
    component: () => import(/* webpackChunkName: "AnalyticsCalendarDetails" */ '@/sheets/analytics/calendar/AnalyticsCalendarDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/insights',
    component: () => import(/* webpackChunkName: "AnalyticsInsights" */ '@/sheets/analytics/insights/AnalyticsInsights'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/insight/:id',
    component: () => import(/* webpackChunkName: "AnalyticsInsightDetails" */ '@/sheets/analytics/insights/AnalyticsInsightDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/health_categories',
    component: () => import(/* webpackChunkName: "HealthCategories" */ '@/sheets/analytics/health/HealthCategories'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/health_categories/legal',
    component: () => import(/* webpackChunkName: "AnalyticsHealthCashflow" */ '@/sheets/analytics/health/HealthCategoryDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/health_categories/financial',
    component: () => import(/* webpackChunkName: "AnalyticsHealthCashflow" */ '@/sheets/analytics/health/HealthCategoryDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/health_categories/debt_history',
    component: () => import(/* webpackChunkName: "AnalyticsHealthCashflow" */ '@/sheets/analytics/health/HealthCategoryDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/debt_history',
    component: () => import(/* webpackChunkName: "AnalyticsCreditHistory" */ '@/sheets/analytics/creditHistory/AnalyticsCreditHistory'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/debt_history/off_track',
    component: () => import(/* webpackChunkName: "AnalyticsCreditHistoryOffTrack" */ '@/sheets/analytics/creditHistory/AnalyticsCreditHistoryOffTrack'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/debt_history/:id',
    component: () => import(/* webpackChunkName: "AnalyticsCreditHistoryDetails" */ '@/sheets/analytics/creditHistory/AnalyticsCreditHistoryDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/debt_history/:id/historical_payments',
    component: () => import(/* webpackChunkName: "AnalyticsCreditHistoryPayments" */ '@/sheets/analytics/creditHistory/AnalyticsCreditHistoryPayments'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/health_categories/cashflow',
    component: () => import(/* webpackChunkName: "AnalyticsHealthCashflow" */ '@/sheets/analytics/health/HealthCategoryDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/profile',
    component: () => import(/* webpackChunkName: "AnalyticsProfile" */ '@/sheets/analytics/profile/AnalyticsProfile'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/profile/details',
    component: () => import(/* webpackChunkName: "AnalyticsProfileDetail" */ '@/sheets/analytics/profile/AnalyticsProfileDetail'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/profile/boardmembers',
    component: () => import(/* webpackChunkName: "AnalyticsBoardMembers" */ '@/sheets/analytics/profile/AnalyticsBoardMembers'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/profile/shareholders',
    component: () => import(/* webpackChunkName: "AnalyticsBusinessOwners" */ '@/sheets/analytics/profile/AnalyticsBusinessOwners'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/profile/enquirers',
    component: () => import(/* webpackChunkName: "AnalyticsProfileEnquirer" */ '@/sheets/analytics/profile/AnalyticsEnquirers'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/cashflow/your_bank_account',
    component: () => import(/* webpackChunkName: "BankingAnalysis" */ '@/sheets/analytics/BankingAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/cashflow/account_transactions',
    component: () => import(/* webpackChunkName: "BankingAnalysis" */ '@/sheets/analytics/TransactionAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/cashflow/account_trends',
    component: () => import(/* webpackChunkName: "BankingAnalysis" */ '@/sheets/analytics/CategorisedTransactionAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/loans',
    component: () => import(/* webpackChunkName: "AnalyticsLoans" */ '@/sheets/analytics/loans/AnalyticsLoans'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/home/loan/details',
    component: () => import(/* webpackChunkName: "AnalyticsLoanDetail" */ '@/sheets/analytics/loans/AnalyticsLoanDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/ccr/dashboard',
    component: () => import(/* webpackChunkName: "CreditReportDashboard" */ '@/sheets/ccr/CreditReportDashboard')
  },
  {
    path: '/ccr/credit_report',
    component: () => import(/* webpackChunkName: "CreditReportDocuments" */ '@/sheets/ccr/CreditReportDocuments')
  },
  {
    path: '/ccr/info',
    component: () => import(/* webpackChunkName: "CreditReportInfo" */ '@/sheets/ccr/CreditReportInfo')
  },
  {
    path: '/account/billing',
    component: () => import(/* webpackChunkName: "AnalyticsPricing" */ '@/sheets/settings/AnalyticsPricing'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/billing/ios',
    component: () => import(/* webpackChunkName: "AnalyticsPricingIos" */ '@/sheets/settings/AnalyticsPricingIos'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/company_details',
    component: () => import(/* webpackChunkName: "CompanyDetails" */ '@/sheets/CompanyDetails'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/user_profile',
    component: () => import(/* webpackChunkName: "UserProfile" */ '@/sheets/settings/UserProfile'),
    props: { isPublic: false, pages: sheets }
  },
  //{
  //  path: '/account/identity_documents',
  //  component: () => import(/* webpackChunkName: "IdentificationSheet" */ '@/sheets/settings/IdentificationSheet'),
  //  props: { isPublic: false, pages: sheets }
  //},*/
  {
    path: '/finance/application/signatories',
    component: () => import(/* webpackChunkName: "IdentificationSheet" */ '@/sheets/settings/IdentityVerificationPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/identity_documents/:id',
    component: () => import(/* webpackChunkName: "IdentificationSheet" */ '@/sheets/settings/IdentificationSheet'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/application_documents/:id',
    component: () => import(/* webpackChunkName: "ApplicationDocuments" */ '@/onboarding/loanApplication/ApplicationDocuments'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/dashboard/:id',
    component: () => import(/* webpackChunkName: "BankingAnalysis" */ '@/sheets/analytics/BankingAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  /*{
    path: '/help_centre/resources',
    component: () => import('@/sheets/ResourcesSheet')
  },*/
  {
    path: '/help_centre/faqs',
    component: () => import(/* webpackChunkName: "FaqsSheet" */ '@/sheets/help/FaqsSheet'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/help_centre/guide',
    component: () => import(/* webpackChunkName: "PlatformGuide" */ '@/sheets/help/PlatformGuide'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/help_centre/terms_conditions',
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ '@/sheets/help/TermsAndConditions'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/help_centre/privacy_policy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/sheets/help/PrivacyPolicy'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/help_centre/complaints_policy',
    component: () => import(/* webpackChunkName: "ComplaintsPolicy" */ '@/sheets/help/ComplaintsPolicy'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/help_centre/support',
    component: () => import(/* webpackChunkName: "SupportQuery" */ '@/sheets/help/SupportQuery'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/dashboard_alt',
    component: () => import(/* webpackChunkName: "MainDashboard" */ '@/sheets/MainDashboard'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/testimonials',
    component: () => import(/* webpackChunkName: "UserTestimonials" */ '@/pages/UserTestimonials'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/showcase',
    component: () => import(/* webpackChunkName: "ShowCase" */ '@/pages/ShowCase'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/apply',
    component: () => import(/* webpackChunkName: "MarketingFinancePage1" */ '@/pages/MarketingFinancePage1'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/application',
    component: () => import(/* webpackChunkName: "FinanceApplication" */ '@/sheets/finance/FinanceApplication'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/dashboard',
    component: () => import(/* webpackChunkName: "FinanceDashboard" */ '@/sheets/finance/FinanceDashboard'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/merchant_transactions',
    component: () => import(/* webpackChunkName: "MerchantTransactions" */ '@/sheets/finance/MerchantTransactions'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/loans',
    component: () => import(/* webpackChunkName: "LoansSheet" */ '@/sheets/finance/LoansSheet'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/loan_offer',
    component: () => import(/* webpackChunkName: "LoanOffer" */ '@/sheets/finance/LoanOffer'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/repayments',
    component: () => import(/* webpackChunkName: "RepaymentsSheet" */ '@/sheets/finance/RepaymentsSheet'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/term_repayments',
    component: () => import(/* webpackChunkName: "TermRepayments" */ '@/sheets/finance/TermRepayments'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/merchant_changeover',
    component: () => import(/* webpackChunkName: "MerchantChangeover" */ '@/sheets/finance/MerchantChangeover'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/terminal_income',
    component: () => import(/* webpackChunkName: "TerminalIncome" */ '@/sheets/finance/TerminalIncome'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/rai',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/truepos',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/iocsave',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/planet',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/aibms',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/AIBMSPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/guaranteed_irish',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/frl',
    component: () => import(/* webpackChunkName: "FrlPage" */ '@/pages/FrlPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/submitted_application',
    component: () => import(/* webpackChunkName: "LoanApplicationPage" */ '@/onboarding/loanApplication/LoanApplicationSubmittedPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/finance/chambers_ireland',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/chambers_fingal',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/chambers_limerick',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/chambers_letterkenny',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/chambers_dublin',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/chambers_cork',
    component: () => import(/* webpackChunkName: "FinancePage" */ '@/pages/FinancePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/partner/connect_account',
    component: () => import(/* webpackChunkName: "PartnerConnectPage" */ '@/onboarding/partners/PartnerConnectPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/downloads',
    component: () => import(/* webpackChunkName: "DownloadsSheet" */ '@/sheets/shared/DownloadsSheet'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/top_up',
    component: () => import(/* webpackChunkName: "RenewalsPage" */ '@/pages/RenewalsPage'),
    props: { isPublic: false, pages: sheets }
  },
  // {
  //   path: '/an_post',
  //   component: () => (process.env.NODE_ENV !== 'production') ? import(/* webpackChunkName: "AnPostPage" */ '@/pages/AnPostPage') : import(/* webpackChunkName: "ErrorPage" */ '@/pages/ErrorPage'),
  //   props: { isPublic: true, pages: publicPages }
  // },
  {
    path: '/partners',
    component: () => import(/* webpackChunkName: "PartnersPage" */ '@/pages/PartnersPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/short_term_loans',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansPage'),
    props: { isPublic: true, pages: publicPages, metaData: { title: 'Business Loans Up to €500k within 48 in Ireland | Flexible Repayment' } }
  },
  {
    path: '/short_term_loans/beauty_salon',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Beauty salons in Ireland | GRID', description: 'Explore tailored business loans for Beauty Salons in Ireland with GRID. Secure the funds you need today. Apply now!' }
    }
  },
  {
    path: '/short_term_loans/community',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Community purpose in Ireland', description: "Explore business loans in Ireland dedicated to Community causes. Empower your local project with GRID's financial support. Apply today!" }
    }
  },
  {
    path: '/short_term_loans/ecommerce',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Ecommerce in Ireland | GRID', description: "Fuel your Irish eCommerce venture with GRID's business loans. Tailored financing to elevate your online presence. Apply now!" }
    }
  },
  {
    path: '/short_term_loans/food_drink',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Food & Drink Industry in Ireland | GRID', description: "Explore tailored loans for Ireland's Food & Drink sector with GRID. Get the right funding for your business needs. Apply now!" }
    }
  },
  {
    path: '/short_term_loans/healthcare',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Healtcare in Ireland | GRID', description: "Explore tailored business loans for Healthcare in Ireland with GRID. Secure your clinic's future. Apply now!" }
    }
  },
  {
    path: '/short_term_loans/hospitality',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Hospitality in Ireland | GRID', description: 'Discover specialised business loans for Hotels, Restaurants, and Cafes in Ireland with GRID. Secure the funds you need today. Apply now!' }
    }
  },
  {
    path: '/short_term_loans/manufacturing',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Manufacturing in Ireland | GRID', description: "Explore bespoke business loans for Manufacturing businesses in Ireland with GRID. Secure your company's growth today. Apply now!" }
    }
  },
  {
    path: '/short_term_loans/motor_sector',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for the Motor sector in Ireland | GRID', description: "Explore specialised business loans for Ireland's Motor Sector with GRID. Tailored financing for your auto venture. Apply now!" }
    }
  },
  {
    path: '/short_term_loans/retailers',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for Retailers in Ireland | GRID', description: "Unlock growth for your Irish Retail store with GRID's tailored business loans. Elevate your inventory. Act now!" }
    }
  },
  {
    path: '/short_term_loans/wholesale',
    component: () => import(/* webpackChunkName: "STBusinessLoansPage" */ '@/pages/STBusinessLoansDetailsPage'),
    props: {
      isPublic: true,
      pages: publicPages,
      metaData: { title: 'Business Loans for the Wholesale Industry in Ireland | GRID', description: 'Discover tailored financing solutions for the wholesale industry in Ireland. Secure the funds you need with GRID. Apply today!' }
    }
  },
  {
    path: '/about_us',
    component: () => import(/* webpackChunkName: "AboutUsPage" */ '@/pages/AboutUsPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/about/team',
    component: () => import(/* webpackChunkName: "TeamPage" */ '@/pages/TeamPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/about/foundation',
    component: () => import(/* webpackChunkName: "FoundationPage" */ '@/pages/FoundationPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/blog',
    component: () => import(/* webpackChunkName: "BlogPage" */ '@/pages/BlogPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/blog/:slug',
    component: () => import(/* webpackChunkName: "BlogArticlePage" */ '@/pages/BlogArticlePage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "LoginPage" */ '@/pages/LoginPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/register_confirmation',
    component: () => import(/* webpackChunkName: "RegisterConfirmationPage" */ '@/pages/RegisterConfirmationPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/analytics/demo_dashboard_1',
    component: () => import(/* webpackChunkName: "MainDashboard" */ '@/sheets/MainDashboard'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/analytics/demo_dashboard_2',
    component: () => import(/* webpackChunkName: "DemoSheet2" */ '@/sheets/DemoSheet2'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/analytics/demo_dashboard_3',
    component: () => import(/* webpackChunkName: "DemoSheet3" */ '@/sheets/DemoSheet3'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/analytics/demo_dashboard_4',
    component: () => import(/* webpackChunkName: "DemoSheet4" */ '@/sheets/DemoSheet4'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/finance/turnover',
    component: () => import(/* webpackChunkName: "MerchantTurnover" */ '@/sheets/finance/MerchantTurnover'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/open_banking',
    component: () => import(/* webpackChunkName: "OpenBanking" */ '@/sheets/analytics/OpenBanking'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/banking_analysis',
    component: () => import(/* webpackChunkName: "BankingAnalysis" */ '@/sheets/analytics/BankingAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/transaction_analysis',
    component: () => import(/* webpackChunkName: "TransactionAnalysis" */ '@/sheets/analytics/TransactionAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/categorised_transaction_analysis',
    component: () => import(/* webpackChunkName: "CategorisedTransactionAnalysis" */ '@/sheets/analytics/CategorisedTransactionAnalysis'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/sector_comparison',
    component: () => import(/* webpackChunkName: "SectorComparison" */ '@/sheets/analytics/SectorComparison'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/macroeconomic_hub',
    component: () => import(/* webpackChunkName: "MacroeconomicHub" */ '@/sheets/analytics/MacroeconomicHub'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/analytics/grid_score',
    component: () => import(/* webpackChunkName: "GridScore" */ '@/sheets/analytics/GridScore'),
    props: { isPublic: false, pages: sheets }
  },
  // {
  //   path: '/analytics/company_health',
  //   component: () => import(/* webpackChunkName: "CompanyHealth" */ '@/sheets/CompanyHealth'),
  //   props: { isPublic: false, pages: sheets }
  // },
  {
    path: '/onboarding/customer',
    component: () => import(/* webpackChunkName: "CustomerOnboardingPage" */ '@/onboarding/user/CustomerOnboardingPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/onboarding/company',
    component: () => import(/* webpackChunkName: "CompanyOnboardingPage" */ '@/onboarding/company/CompanyOnboardingPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/onboarding/loan_application',
    component: () => import(/* webpackChunkName: "LoanApplicationPage" */ '@/onboarding/loanApplication/LoanApplicationPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/identity_documents',
    component: () => import(/* webpackChunkName: "CreditHistoryPage" */ '@/onboarding/creditHistory/CreditHistoryPage'),
    props: { isPublic: true, pages: sheets }
  },
  {
    path: '/notifications',
    component: () => import(/* webpackChunkName: "NotificationsPage" */ '@/pages/NotificationsPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/test_components',
    component: () => import(/* webpackChunkName: "TestComponentsPage" */ '@/pages/TestComponentsPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/manage_open_banking',
    component: () => import(/* webpackChunkName: "ManageOpenBanking" */ '@/sheets/settings/ManageOpenBanking'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/account/analytics_billing',
    component: () => import(/* webpackChunkName: "AnalyticsPricing" */ '@/sheets/settings/AnalyticsPricing'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/open_banking_instructions',
    component: () => import(/* webpackChunkName: "OpenBankingInstructionsPage" */ '@/onboarding/help/OpenBankingInstructionsPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/open_banking_instructions',
    component: () => import(/* webpackChunkName: "OpenBankingInstructionsPage" */ '@/onboarding/help/OpenBankingInstructionsPage'),
    props: { isPublic: false, pages: sheets }
  },
  {
    path: '/sme_health_index_survey',
    component: () => import(/* webpackChunkName: "SendEmailPage" */ '@/pages/SendEmailPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/register_interest_analytics',
    component: () => import(/* webpackChunkName: "SendEmailPage" */ '@/pages/SendEmailPage'),
    props: { isPublic: true, pages: publicPages }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '@/pages/ErrorPage'),
    props: { isPublic: true, pages: publicPages }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const el = window.location.href.split("#")[1];
        if (el.length) {
          console.log("el: ", el);
          document.getElementById(el).scrollIntoView({ behavior: "smooth" });
        }
      }, 300)
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      console.log("No saved position");
    }
  },
  routes
})

const checkQueryParams = async (query) => {
  return new Promise((resolve, reject) => {
    if (Object.keys(query).length > 0) resolve(query)
    else resolve(null)
  });
};

const evaluateAuthPath = async (pages, path, query) => {
  return new Promise((resolve, reject) => {
    if (path.includes('/finance/?')) resolve(false)
    if (path.includes('/analytics/?')) resolve(false)
    if (path.includes('/analytics/')) resolve(true)
    if (path.includes('/finance/')) resolve(true)
    if (path.includes('/partner/')) resolve(false)
    if (path.includes('/blog/')) resolve(false)
    if (path.includes('/short_term_loans/')) resolve(false)
    if (path.includes('/account/identity_documents')) resolve(false)
    pages.find(page => {
      if (page.includes(path)) resolve(false)
    })
    resolve(true)
  });
};

router.beforeEach(async (to, from, next) => {
  if (isPlatform('mobile')) {
    let isOpen = await menuController.isOpen('main-side-menu');
    if (isOpen) await menuController.enable(false, 'main-side-menu');
  }
  let query = await checkQueryParams(to.query);
  const userStore = useUserStore();
  if (!Capacitor.isNativePlatform()) await userStore.instantiateUserCookie(window.$cookies);
  const navStore = useNavStore();
  const authRequired = await evaluateAuthPath(navStore.publicPages, to.fullPath, to.query);
  await navStore.storePreviousPath(to && to.fullPath);
  const loggedInUser = (userStore.userCookie && userStore.userCookie === 'true') || userStore.userObj.loggedInUser;

  if (to.fullPath === '/login' && Capacitor.isNativePlatform() && loggedInUser) next({ path: '/home', query: query && query })

  let redirectPage = await navStore.includesRedirectPage(to.fullPath);
  if (redirectPage) next()

  if (authRequired && (!loggedInUser || userStore?.userObj?.isSignatory)) {
    if (to.path === '/') next()
    else next({ path: '/', query: query && query })
  }
  else next()
});

router.afterEach(async (to, from, next) => {
  if (isPlatform('mobile')) {
    let isOpen = await menuController.isOpen('main-side-menu');
    if (isOpen) await menuController.enable(false, 'main-side-menu');
  }
});

export default router
